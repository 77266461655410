import request from "@/utils/request"
import qs from "qs"
export function getList(data) {
  return request({
    url: "/admin/order/index",
    method: "post",
    data: qs.stringify(data),
  })
}
export function getIndexSelectData(data) {
  return request({
    url: "/admin/order/getIndexSelectData",
    method: "post",
    data: qs.stringify(data),
  })
}
export function getOrderStatList(data) {
  return request({
    url: "/admin/order/getOrderStatList",
    method: "post",
    data: qs.stringify(data),
  })
}
export function getOrderStatHeader(data) {
  return request({
    url: "/admin/order/getOrderStatHeader",
    method: "post",
    data: qs.stringify(data),
  })
}
export function getMerchantRechargeSettlementList(data) {
  return request({
    url: "/admin/order/getMerchantRechargeSettlementList",
    method: "post",
    data: qs.stringify(data),
  })
}
export function getMerchantRechargeSettlementHeader(data) {
  return request({
    url: "/admin/order/getMerchantRechargeSettlementHeader",
    method: "post",
    data: qs.stringify(data),
  })
}

/**
 * 获取组员统计数据
 * @param {*} params
 * @returns
 */
export function getCrewStaticalData(params) {
  return request({
    url: "/admin/order/crew_overview",
    method: "post",
    params,
  })
}

/**
 * 获取组员每天的充值相关数据
 * @param {*} params
 * @returns
 */
export function getMemberListByDay(params) {
  return request({
    url: "/admin/order/member_list",
    method: "post",
    params,
  })
}

export function getDefaultColumns() {
  return [
    {
      label: "充值来源",
      value: "video_name_number",
      fixed: true,
      fixed_position: "left",
      width: 250,
      custom_template: true,
    },
    {
      label: "广告计划ID",
      value: "platform_promotionid",
      fixed: true,
      fixed_position: "left",
      width: 235,
      custom_template: true,
    },
    {
      label: "回传状态",
      value: "is_callback",
      fixed: true,
      fixed_position: "left",
      width: 0,
      custom_template: true,
    },
    {
      label: "支付状态",
      value: "status",
      fixed: false,
      fixed_position: "",
      width: 80,
      custom_template: true,
    },
    {
      label: "来源平台",
      value: "platform",
      fixed: false,
      fixed_position: "left",
      width: 180,
      custom_template: true,
    },
    {
      label: "用户ID",
      value: "uid",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "注册日期",
      value: "user_create_time",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: false,
    },
    {
      label: "所属代理",
      value: "merchant_nickname",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: false,
    },
    {
      label: "机型",
      value: "machine",
      fixed: false,
      fixed_position: "",
      width: 250,
      custom_template: false,
    },
    {
      label: "累计充值",
      value: "total_recharge_money",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "充值金额",
      value: "amount",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "充值类型",
      value: "type_txt",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "充值信息",
      value: "look_point_vip_days",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: true,
    },
    {
      label: "下单时间",
      value: "create_time",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: false,
    },
    {
      label: "支付时间",
      value: "pay_time",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: true,
    },
    {
      label: "染色时间",
      value: "color_time",
      fixed: false,
      fixed_position: "",
      width: 150,
      custom_template: false,
    },
    {
      label: "推广ID",
      value: "promotion_id",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "推广名称",
      value: "promotion_link_name",
      fixed: false,
      fixed_position: "",
      width: 180,
      custom_template: true,
    },
    {
      label: "推广链接剧名",
      value: "promotion_video_name",
      fixed: false,
      fixed_position: "",
      width: 200,
      custom_template: false,
    },
    {
      label: "下单ip",
      value: "ip",
      fixed: false,
      fixed_position: "",
      width: 130,
      custom_template: false,
    },
    {
      label: "充值次数",
      value: "total_recharge_count",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "退款金额",
      value: "refund_amount",
      fixed: false,
      fixed_position: "",
      width: 0,
      custom_template: false,
    },
    {
      label: "订单编号",
      value: "orderno",
      fixed: false,
      fixed_position: "",
      width: 210,
      custom_template: false,
    },
    {
      label: "交易单号",
      value: "transaction_id",
      fixed: false,
      fixed_position: "left",
      width: 235,
      custom_template: false,
    },
  ]
}
