const getters = {
  sidebar: (state) => state.app.sidebar,
  orderListactiveName: (state) => state.app.orderListactiveName,
  selectedColumns: (state) => state.app.selectedColumns,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  mini_info: (state) => state.user.mini_info,
  routes: (state) => state.user.routes,
  department: (state) => state.user.department,
  menus: (state) => state.user.menus,
  first_route_path: (state) => state.user.first_route_path,
  dynamic_auth: (state) => state.user.dynamic_auth,
  tdl_view_type: (state) => state.user.tdl_view_type,
  dist_timestamp: (state) => state.user.dist_timestamp,
  service_mode: (state) => state.user.serviceMode,
  pid: (state) => state.user.pid,
  admin_menus: (state) => state.user.admin_menus,
}

export default getters
