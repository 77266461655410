import request from '@/utils/request'
import qs from 'qs'

const api = {
  addSub: '/admin/merchants/addSub',
  getSubList: '/admin/merchants/subList',
  getMerchant: '/admin/merchants/getMerchant',
  updateMerchant: '/admin/merchants/update',
  deleteMerchant: '/admin/merchants/delete',
  disableMerchant: '/admin/merchants/disable',
  loginMerchant: '/admin/merchants/login',
  addWhieList: '/admin/merchants/addWhitelist',
  refreshSecret: '/admin/merchants/refreshSecret',
  subOpts: '/admin/merchants/subOpts',
}

/**
 * 获取子账户的列表数据
 * @param {Json} params 
 * @returns 
 */
export function getSubMerchants(params)
{
  return request({
    url: api.getSubList,
    method: 'get',
    params
  });
}

/**
 * 添加子账户数据
 * @param {Json} params 
 * @returns 
 */
export function postSubMerchant(params)
{
  return request({
    url: api.addSub,
    method: 'post',
    data: qs.stringify(params)
  })
}

/**
 * 获取指定组员信息
 * @param {Integer} id 
 * @returns 
 */
export function getMerchant(id)
{
  return request({
    url: `${api.getMerchant}?id=${id}`,
    method: 'get'
  })
}

/**
 * 更新指定组员信息
 * @param {Json} params 
 * @returns 
 */
export function saveSubMerchant(params)
{
  return request({
    url: api.updateMerchant,
    method: 'put',
    data: qs.stringify(params)
  })
}

/**
 * 删除指定组员信息
 * @param {*} id 
 * @returns 
 */
export function deleteMerchant(id)
{
  return request({
    url: api.deleteMerchant,
    method: 'delete',
    data: qs.stringify({id})
  })
}


export function disableMerchant(id, status)
{
  return request({
    url: api.disableMerchant,
    method: 'delete',
    data: qs.stringify({id, status})
  })
}
/**
 * 登陆分销商账户
 * @param {object} params 
 * @returns 
 */
export function merchantLogin(params)
{
  return request({
    url: api.loginMerchant,
    method: 'post',
    data: qs.stringify(params)
  })
}

/**
 * 添加白名单信息
 * @param {object} params 
 * @returns 
 */
export function addWhitelist(params)
{
  return request({
    url: api.addWhieList,
    method: 'post',
    data: qs.stringify(params)
  })
}

/**
 * 刷新商户对外API的密钥
 * @param {integer} id 商户ID
 * @returns 
 */
export function refreshSecret(id)
{
  return request({
    url: api.refreshSecret,
    method: 'post',
    data: qs.stringify({id})
  })
}

/**
 * 获取某个组长下的所有组员互数据
 * @param {integer} id 组长ID
 * @returns 
 */
export function getSubOpts()
{
  return request({
    url: `${api.subOpts}`,
    method: 'get',
  })
}