import router from "./router"
import store from "./store"
import Cookies from "js-cookie"
import NProgress from "nprogress" // progress bar
import Layout from "@/layout"
import { Message, MessageBox } from "element-ui"
import "nprogress/nprogress.css" // progress bar style
import { getToken } from "./store/module/user"
import settings from "@/store/module/settings"

const MyServiceModeKey = "my_vue_admin_service_mode"

const title = settings.state.title

export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

const allowedRoutes = [
  "admin/index",
  "admin/clearCache",
  "menu/index",
  "menu/AddMenu",
  "member/index",
  "member/addMember",
  "Authorize/index",
  "Authorize/addGroup",
  "short_videos/index",
  "short_videos/promotion",
  "short_videos/config",
  'orderManagement/index',
  'orderManagement/dayIndex',
  'orderManagement/orderList',
  'orderManagement/settlementList',
  'orderManagement/crewGeneralView',
  'orderManagement/userList',
  'userManagement/index',
  'download/index',
  'recharge/customTemplate',
  'recharge/promotion',
  'ttCallBack/index',
  'ttCallBack/setting',
  'notice/index',
  'notice/upload',
  'notice/new',
  'notice/activity',
  'personal/dataPage',
  'feedback/index',
  'member/userList',
  "short_videos/videoStat",
  "operate/index",
  "operate/community",
  "timing/index",
  "link/index",
  "operate/kfState",
  "timing/timeLine",
  "operate/staticCommunity",
  "operate/staticKf",
  "operate/customerLink",
  "operate/complaint",
  "contact/list",
  "qwtag/list",
  "grouptag/index",
  "operate/welcome"
]

function addNewRoutes(newroutes, routes) {
  let newroute = {
    id: 0,
    path: "/",
    component: Layout,
    meta: { title: "后台首页" },
  }
  if (routes.length > 0) {
    newroute["children"] = []

    for (let k in routes) {
      let rout_p =
        allowedRoutes.indexOf(routes[k]["name"]) !== -1
          ? routes[k]["name"]
          : "admin/empty"
      let tmp = import("@/views/" + rout_p)
      newroute["children"].push({
        id: routes[k]["id"],
        path: "/" + routes[k]["name"],
        name: "id" + routes[k]["id"],
        component: () => tmp,
        meta: {
          title: routes[k]["title"],
          icon: routes[k]["icon"],
          affix: false,
        },
      })
    }
  }
  newroutes.push(newroute)
}

function checkRouteExists(newroutes, path) {
  for (let k in newroutes) {
    let newroute = newroutes[k]
    if (path === newroute["path"]) {
      return true
    } else if (newroute["children"]) {
      for (let kk in newroute["children"]) {
        let children = newroute["children"][kk]
        if (children["path"] === path) {
          return true
        }
      }
    }
  }
  return false
}

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  "/admin/login",
  "/admin/wwlogin",
  "/admin/fslogin",
  "/admin/fsmdlogin",
] // no redirect whitelist

let getinfo_data = false

let global_first_route = "/admin/index"

let route_dist_timestamp = null

router.beforeEach(async (to, from, next) => {
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()

  if (hasToken) {
    if (
      to.path === "/admin/login" ||
      to.path === "/admin/wwlogin" ||
      to.path === "/admin/fslogin" ||
      to.path === "/admin/fsmdlogin"
    ) {
      next({ path: global_first_route })
      NProgress.done()
    } else {
        const hasRoutes = store.getters.routes && store.getters.routes.length > 0;
        if (hasRoutes) {
          // 检测如果当前去的界面路由不在授权范围内直接跳转到主页
          const adminMenus = store.getters.admin_menus;
          let routeIsAuth = checkToRouteIsAuth(to.path, adminMenus);
          if (!routeIsAuth && to.path != '/admin/index') {
            next({path: global_first_route});
            NProgress.done();
          }

          if (route_dist_timestamp === null) {
            route_dist_timestamp = store.getters.dist_timestamp
          } else if (route_dist_timestamp !== store.getters.dist_timestamp) {
            // MessageBox.alert("检测到新版本，点确定刷新当前浏览器", "新版本提示", {
            //   callback: (action) => {
            //     window.location.reload()
            //   },
            // })
            window.location.reload()
          }
          if (to.path === "/") {
            window.location.reload()
          } else if (
            to.path == "/admin/index" &&
            global_first_route != "/admin/index"
          ) {
            next({ path: global_first_route, replace: true })
          } else {
            next()
          }
      } else if (!getinfo_data) {
        try {
          const { routes, first_route_path, pcRouterConfig, adm_menus } =
            await store.dispatch("user/getInfo")

          // 检测如果当前去的界面路由不在授权范围内直接跳转到主页
          let routeIsAuth = checkToRouteIsAuth(to.path, adm_menus);
          if (!routeIsAuth && to.path != '/admin/index') {
            next({path: global_first_route});
            NProgress.done();
            return ;
          }
          
          let pathname = window.location.pathname
          if (
            process.env.NODE_ENV === "production" &&
            pathname != `/${pcRouterConfig.path}${pcRouterConfig.version}/`
          ) {
            // MessageBox.alert(
            //   "检测到新版本，点确定刷新当前浏览器",
            //   "新版本提示",
            //   {
            //     callback: (action) => {
            //       let domain = pcRouterConfig.domain
            //       let currentUrl =
            //         `${domain}${pcRouterConfig.path}${pcRouterConfig.version}/#` +
            //         to.path
            //       window.location.href = currentUrl
            //     },
            //   }
            // )
            let domain = pcRouterConfig.domain
            let currentUrl =
              `${domain}${pcRouterConfig.path}${pcRouterConfig.version}/#` +
              to.path
            window.location.href = currentUrl
          }

          global_first_route = first_route_path

          let newroutes = []
          addNewRoutes(newroutes, routes)

          router.addRoutes(newroutes)
          router.options.routes.push(...newroutes)
          await store.dispatch("user/setRoutes", newroutes)
          await store.dispatch("user/setAdminMenus", adm_menus);
          getinfo_data = true
          /* if(newroutes.length > 0 && !checkRouteExists(newroutes, to.path)) {
            next({path: newroutes[0]['path'], replace: true})
          } */

          let cookie_service_mode = Cookies.get(MyServiceModeKey)
          await store.dispatch("user/setServiceMode", cookie_service_mode)

          if (
            (from.path === "/admin/login" ||
              from.path === "/admin/fslogin" ||
              from.path === "/admin/fsmdlogin") &&
            to.path === "/admin/index"
          ) {
            next({ path: first_route_path, replace: true })
          } else if (to.path === "/") {
            next({ path: first_route_path, replace: true })
          } else {
            next({ ...to, replace: true })
          }
        } catch (error) {
          Message.error(error || "Has Error")
          NProgress.done()
        }
      } else {
        Message.error("未获取到权限信息")
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      //next(`/admin/fslogin?redirect=${to.path}`)
      next("/admin/wwlogin")
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export function setGetInfoDataValue(val = false) {
  getinfo_data = val
}

/**
 * 检测当前要去的界面路由是否在权限范围中
 * @param {String} toRoute 目的路由
 * @param {Array} admMenus 菜单
 * @returns 
 */
export function checkToRouteIsAuth(toRoute, admMenus)
{
  if (admMenus.length > 0 && Array.isArray(admMenus)) {
    for (let menu of admMenus) {
      let subMenus = menu?.submenu || [];
      if (subMenus.length > 0 && checkToRouteIsAuth(toRoute, subMenus)) {
        return true;
      }

      let menuPath = `/${menu.name.trim()}`
      if (menuPath == toRoute.trim()) {
        return true;
      }
    }
  }

  return false;
}
