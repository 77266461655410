import request from '@/utils/request'
import qs from 'qs'

export function getSubordinate(params)
{
  return request({
    url: '/admin/dynamic.common/getSubordinate',
    method: 'post',
    params
  })
}

export function check_detect(params)
{
  return request({
    url: '/admin/dynamic.common/check_detect',
    method: 'get',
    params
  })
}

/**
 * 获取指定类型的字典数据
 * @param {Number} type 字典类型
 * @returns 
 */
export function getDictByType(type)
{
  return request({
    url: `/admin/getDictByType/info?type=${type}`,
    method: 'get',
  })
}