import Cookies from "js-cookie"
import { getDefaultColumns } from "@/api/orderManagement/orderList"

const state = {
  sidebar: {
    opened: Cookies.get("sidebarStatus")
      ? !!+Cookies.get("sidebarStatus")
      : true,
    withoutAnimation: false,
  },
  orderListactiveName: "detail",
  selectedColumns: getDefaultColumns(),
}

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set("sidebarStatus", 1)
    } else {
      Cookies.set("sidebarStatus", 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set("sidebarStatus", 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  CHANGE_ORDER_LIST_ACTIVE_NAME: (state, activeName) => {
    state.orderListactiveName = activeName
  },
  CHANGE_SELECTED_COLUMNS: (state, columns) => {
    state.selectedColumns = columns
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR")
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation)
  },
  changeOrderListactiveName({ commit }, activeName) {
    commit("CHANGE_ORDER_LIST_ACTIVE_NAME", activeName)
  },
  changeSelectedColumns({ commit }, columns) {
    commit("CHANGE_SELECTED_COLUMNS", columns)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
